import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/compare-banner"
import ComparisonTable from "../components/comparison-table"
import { Helmet } from 'react-helmet'
import SingleContent from "../components/single-column"
import BadgeContent from "../components/badge-component"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const bannerContent = {
    height: "auto",
    sectionCompared: "compared",
    title: "The result are in. Customers love Billsby's best-in-class subscription billing software",
    text: "We're proud that Billsby is some of the highest rated and most affordable subscription billing software around.",
}

const comparisonTableContent = {
    title: 'The highest rated subscription billing software',
    description: "Compared to our major competitors, Billsby is easier to use, setup and has higher quality support. But don't take our word for it. Independently collected review data from G2 shows how Billsby beats the competition.",
    heading: [
      {
        logo: 'billsby_logo_blue.png',
        title: 'Billsby' 
      },
      {
        logo: require('../images/chargebee.svg'),
        title: 'billwerk',
        svgLogo: true,
      },
      {
        logo: require('../images/chargify.svg'),
        title: 'billwerk',
        svgLogo: true,
      },
      {
        logo: require('../images/recurly.png'),
        title: 'billwerk',
        svgLogo: true,
      },
      {
        logo: require('../images/stripe-billing.png'),
        title: 'billwerk',
        svgLogo: true,
      },
    ],
    body: [
      {
        title: 'Overall score',
        content: [
          {
            itemContent: "4.9 / 5"
          },
          {
            itemContent: "4.7 / 5"
          },
          {
            itemContent: "4.3 / 5"
          },
          {
            itemContent: "4.2 / 5"
          },
          {
            itemContent: "4.5 / 5"
          },
        ]
      },
      {
        title: 'Meets requirements',
        content: [
            {
                itemContent: "9.4 / 10"
            },
            {
                itemContent: "9.2 / 10"
            },
            {
                itemContent: "8.8 / 10"
            },
            {
                itemContent: "8.2 / 10"
            },
            {
                itemContent: "9.4 / 10"
            },
        ]
      },
      {
        title: 'Ease of use',
        content: [
            {
                itemContent: "9.8 / 10"
            },
            {
                itemContent: "9.1 / 10"
            },
            {
                itemContent: "8.5 / 10"
            },
            {
                itemContent: "8.4 / 10"
            },
            {
                itemContent: "9.2 / 10"
            },
        ]
      },
      {
        title: 'Ease of setup',
        content: [
            {
                itemContent: "9.8 / 10"
            },
            {
                itemContent: "8.7 / 10"
            },
            {
                itemContent: "8.1 / 10"
            },
            {
                itemContent: "7.9 / 10"
            },
            {
                itemContent: "8.4 / 10"
            },
        ]
      },
      {
        title: 'Ease of admin',
        content: [
            {
                itemContent: "9.8 / 10"
            },
            {
                itemContent: "9.1 / 10"
            },
            {
                itemContent: "8.6 / 10"
            },
            {
                itemContent: "8.4 / 10"
            },
            {
                itemContent: "8.9 / 10"
            },
        ]
      },
      {
        title: 'Quality of support',
        content: [
            {
                itemContent: "9.8 / 10"
            },
            {
                itemContent: "9.1 / 10"
            },
            {
                itemContent: "8.8 / 10"
            },
            {
                itemContent: "8.1 / 10"
            },
            {
                itemContent: "8.6 / 10"
            },
        ]
      },
      {
        title: 'Ease of doing business with',
        content: [
            {
                itemContent: "10 / 10"
            },
            {
                itemContent: "9.2 / 10"
            },
            {
                itemContent: "9.1 / 10"
            },
            {
                itemContent: "7.9 / 10"
            },
            {
                itemContent: "8.4 / 10"
            },
        ]
      },
      {
        title: 'Product direction',
        subtitle: '% POSITIVE',
        content: [
            {
                itemContent: "8.7 / 10"
            },
            {
                itemContent: "9.3 / 10"
            },
            {
                itemContent: "8.2 / 10"
            },
            {
                itemContent: "6.3 / 10"
            },
            {
                itemContent: "9.7 / 10"
            },
        ]
      },
    ],
    buttons: [
      {
        url: 'https://app.billsby.com/registration',
        buttonColor: 'orange',
        text: 'Sign up for free',
        isExternal: true
      },
      {
        scheduleButton: true,
        buttonColor: "black"
      }
    ]
}

const comparisonTableContent2 = {
  title: 'The best value recurring payments solution',
  description: "With great reviews, you might expect Billsby to be unaffordable. Not so fast - Billsby is the best value of all the major recurring billing platforms - with no punitive fees before you make any money and a free trial to get you started.",
  heading: [
    {
      logo: 'billsby_logo_blue.png',
      title: 'Billsby' 
    },
    {
      logo: require('../images/chargebee.svg'),
      title: 'billwerk',
      svgLogo: true,
    },
    {
      logo: require('../images/chargify.svg'),
      title: 'billwerk',
      svgLogo: true,
    },
    {
      logo: require('../images/recurly.png'),
      title: 'billwerk',
      svgLogo: true,
    },
    {
      logo: require('../images/stripe-billing.png'),
      title: 'billwerk',
      svgLogo: true,
    },
  ],
  body: [
    {
      title: 'Monthly fee',
      subtitle: 'BASE PLAN',
      content: [
        {
          itemContent: "$0.00"
        },
        {
          itemContent: "$299.00"
        },
        {
          itemContent: "$299.00"
        },
        {
          itemContent: "$149.00"
        },
        {
          itemContent: "$0"
        },
      ]
    },
    {
      title: 'Transaction fee',
      content: [
          {
              itemContent: "0.4%"
          },
          {
              itemContent: "Up to 0.9%"
          },
          {
              itemContent: "Up to 1%"
          },
          {
              itemContent: "0.9%"
          },
          {
              itemContent: "Up to 0.8%"
          },
      ]
    },
    {
      title: 'Free trial',
      content: [
          {
              itemContent: "Make $5k on us"
          },
          {
              itemContent: "Feature restricted"
          },
          {
              itemContent: "No"
          },
          {
              itemContent: "No"
          },
          {
              itemContent: "No"
          },
      ]
    },
  ],
  buttons: [
    {
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      text: 'Sign up for free',
      isExternal: true
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ]
}

const singleContent3 =   {
  title: "Explore away, there's no credit card required",
  text: "We know that sometimes the best way to evaluate a solution is to try it for yourself, so we've made sure that signing up doesn't require a credit card, and our onboarding team are standing by to help with any questions.",
  button: [
    {
      buttonColor: "orange",
      url: "https://app.billsby.com/registration",
      btnText: "Sign up for free",
    },
  ]
}

const badgeData = {
  section: "compared",
  link: false,
  FallBadges: [
    {
      img: require("../images/easiest-to-do-bussiness-with-fall.svg"),
      altText: 'easiest-to-do-bussiness-with-fall',
    },
    {
      img: require("../images/easiest-setup-fall.svg"),
      altText: 'easiest-setup-fall',
    },
    {
      img: require("../images/best-est-roi-fall.svg"),
      altText: 'best-est-roi-fall',
    },
    {
      img: require("../images/high-performance.svg"),
      altText: 'high-performance',
    },
    {
      img: require("../images/user-most-likely-to-recommend-fall.svg"),
      altText: 'user-most-likely-to-recommend-fall',
    },
    {
      img: require("../images/fastest-implementation-fall.svg"),
      altText: 'fastest-implementation-fall',
    },
    {
      img: require("../images/best-meets-requirements-fall.svg"),
      altText: 'best-meets-requirements-fall',
    },
  ],
  SmallBussinessBadge: [
    {
      img: require("../images/best-support.svg"),
      altText: 'best-support',
    },
    {
      img: require("../images/easiest-to-do-bussiness-with.svg"),
      altText: 'easiest-to-do-bussiness-with',
    },
    {
      img: require("../images/easiest-setup.svg"),
      altText: 'easiest-setup',
    },
    {
      img: require("../images/best-est-roi.svg"),
      altText: 'best-est-roi',
    },
    {
      img: require("../images/high-performance-fall.svg"),
      altText: 'high-performance-fall',
    },
    {
      img: require("../images/user-most-likely-to-recommend.svg"),
      altText: 'user-most-likely-to-recommend',
    },
    {
      img: require("../images/fastest-implementation.svg"),
      altText: 'fastest-implementation',
    },
    {
      img: require("../images/best-meets-requirements.svg"),
      altText: 'best-meets-requirements',
    },
    {
      img: require("../images/easiest-admin.svg"),
      altText: 'easiest-admin',
    },
    {
      img: require("../images/easiest-to-use.svg"),
      altText: 'easiest-to-use',
    },
  ]
}

const Compared = () => {
  return(
    <Layout>
      <SEO 
        title="The highest rated subscription management software | Billsby" 
        description="The results are in. Customers love Billsby's best-in-class subscription billing software. We're proud that Billsby is some of the highest rated and most affordable subscription billing software around." 
        url="https://www.billsby.com/compared"
      />

      <Helmet>
          <script type="text/javascript">
            {
              `
              {
                (function () { var on = window.addEventListener ?
                  function (m,c) { window.addEventListener(m,c,false); } : function (m,c) {
                  window.attachEvent('on'+m,c); } , r = function (e) { if (e.origin !== "https://my.g2.com") { return; }
                  var d = document.getElementById('g2-crowd-widget-testimonial-16576'); if(d){d.style.height =
                  e.data}; r = function () {}; } ; on('message', r); }()); 
              }
              `
            }
          </script>
      </Helmet>

        <div className="compared">
          <Banner content={bannerContent}/>
          <BadgeContent content={badgeData} />
          <ComparisonTable content={comparisonTableContent}/>
          <p className="text">
            Data from G2 last updated 11th September 2020 - <a href="https://www.g2.com/categories/subscription-billing" rel="noopener noreferrer" target='_blank'>View subscription billing on G2 for latest data</a>
          </p>
          <ComparisonTable content={comparisonTableContent2}/>
          <div className="review-holder">
            <div className="container">
              <p className="title">Our customers explain it best</p>
              <p className="subheading">Hear direct from our customers why Billsby is the most powerful, customizable and easy to integrate subscription billing software used by hundreds of companies worldwide to simplify revenue operations.</p>
              <iframe frameborder="0" height="100%"
              id="g2-crowd-widget-testimonial-16576" scrolling="no"
              src="https://www.g2.com/products/billsby/testimonials/16576.embed" width="100%"></iframe>
              {/* <a href="https://www.g2.com/products/billsby/reviews?utm_campaign=testimonials_embed&amp;ut
              m_medium=testimonials&amp;utm_source=Billsby">Billsby reviews sourced by G2</a> */}
            </div>
        </div>
        <SingleContent content={singleContent3} />
      </div>
    </Layout>
  )
}

export default Compared;